<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditUnidadeDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <UnidadeDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import UnidadeDataTable from "../../components/shared/datatables/UnidadeDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/unidade";
import CreateEditUnidadeDialog from "../../components/shared/dialogs/CreateEditUnidadeDialog";
import {findAll as findAllOrgao} from '../../api/orgao';
import {ApiError} from "../../api/core";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {CreateEditUnidadeDialog, BaseView, UnidadeDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'ID',
          column: 'id',
          type: 'number',
          prefix: '#'
        },
        {
          name: 'Órgão',
          column: 'idOrgao',
          type: 'autocomplete',
          optionsFactory: (search) => findAllOrgao(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },        
        {
          name: 'Descricao',
          column: 'descricao',
          type: 'text'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },  
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Unidade removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {           
          this.$toast.error(`Não foi possível remover a Unidade.`, {
            timeout: 6000
          })
        }
      }
    },
	  async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Unidade.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>