<template>
<v-row>
  <v-col cols="12" md="7">
    <search-box
        label="* Órgão"
        popup-label="Selecione um Órgão"
        :id.sync="value.idOrgao"
        :descricao.sync="value.descricaoOrgao"
        :hidden-clear="true"
        :value="orgaos"
        :loader-fn="loaderOrgaos"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Órgão encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="2">
    <v-text-field label="* Código"
                  dense
                  :rules="[
                      this.$validators.number.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 5)
                      ]"
                  v-model.trim="value.cdUnidade"/>
  </v-col>  
  <v-col cols="12" md="3">
    <v-text-field label="* Sigla"
                  dense
                  :rules="[
                      this.$validators.string.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                      ]"
                  v-model.trim="value.sigla"/>
  </v-col>
  <v-col cols="12" md="12">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                      ]"
                  v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12">
    <strong>Contato</strong>
    <v-divider/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-text-field label="* Email"
                  dense
                  :rules="[
                    this.$validators.string.email,
                    v => this.$validators.string.lessThanOrEquals(v, 50),
                ]"
                v-model.trim="value.email"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-text-field label="Telefone"
                  dense
                  v-mask="'(##) ####-####'"
                  v-model.trim="value.telefone"/>
  </v-col>    
  <v-col cols="12">
    <strong>Localização</strong>
    <v-divider/>
  </v-col>  
  <v-col cols="12" sm="3">
    <v-text-field label="* CEP"
                  dense
                  v-mask="'########'"
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 8),
                    v => this.$validators.string.lessThanOrEquals(v, 8),
                  ]"
                  v-model.trim="value.cep"/>
  </v-col>
  <v-col cols="12" sm="3">
    <v-text-field label="* Cidade"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                :value="value.cidade"/>
  </v-col>
  <v-col cols="12" sm="3">
    <lookup-dominio label="* Estado"
                    :hidden-clear="true"
                    :value="value.estado"
                    :type="lookups.estado"
                    :rules="[
                        this.$validators.string.required
                    ]"/>
  </v-col>    
  <v-col cols="12" sm="3">
    <v-text-field label="* Bairro"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.bairro"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="* Endereço"
                :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.endereco"/>
    <v-text-field v-else label="* Endereço"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.endereco"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="Complemento"
                :rules="[
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.complemento"/>
    <v-text-field v-else label="Complemento"
                  dense
                  :rules="[
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.complemento"/>
  </v-col>   
</v-row>
</template>

<script>
import {findAll as findAllOrgaos} from "../../../api/orgao";
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {buscaEnderecoViaCep} from '../../../api/correios';
import Vue from 'vue';

export default {
  name: "UnidadeForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        estado: ItemDeDominio.COMBO_ESTADO, 
      },
      loading: false,
      error: null,
    }
  },  
  computed: {
    orgaos() {
      if (this.value.idOrgao == null) return null;
      return {
        id: this.value.idOrgao,
        descricao: this.value.descricaoOrgao
      }
    },
  },
  watch: {
    'value.cep': {
      handler(newValue, oldValue) {
        this.buscaEndereco();
      }
    }
  },  
  methods: {
    loaderOrgaos(page, search) {
      return findAllOrgaos(page, {
        nome: search
      })
    },
    async buscaEndereco(){
      if (this.value.cep !== "" && this.value.cep.length === 8){
        let endereco = await buscaEnderecoViaCep(this.value.cep);
        Vue.set(this.value,'cidade',endereco.localidade);
        Vue.set(this.value,'estado',endereco.uf);
        Vue.set(this.value,'bairro',endereco.bairro);
        Vue.set(this.value,'endereco',endereco.logradouro);
      }
    },    
  }
}
</script>

<style scoped>

</style>